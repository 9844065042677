import React, { cloneElement, useState, useEffect } from 'react';
import {
    DateField,
    Filter,
    ReferenceArrayInput,
    sanitizeListRestProps,
    SelectArrayInput,
    SearchInput,
    SelectInput,
    TextField,
    TopToolbar,
} from 'react-admin';
import { useTranslate } from 'ra-core';
import classnames from 'classnames';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import LinearProgress from '@material-ui/core/LinearProgress';
import {
    AccessField,
    CardGrid,
    Datagrid,
    List,
    ListDisplayButton,
    ListDisplayProvider,
    LIST_DISPLAY_CARDS,
    MarkdownField,
    SortButton,
    SortMenuItem,
    TruncatedTextField,
    useListDisplay,
} from '../ui';
import { ApiCard } from './ApiCard';
import { VisibilityField } from './VisibilityField';
import { TagsField } from './TagsField';
import { LastUpdateField } from './LastUpdateField';
import { readApiHubPreference } from '../preferences';
import { CardContent, CardHeader, Grid, GridList } from '@material-ui/core';

export const ApiList = props => {
    const initialListDisplay = readApiHubPreference(
        listDisplayPreferenceName,
        LIST_DISPLAY_CARDS
    );

    const classes = useStyles();
    const [modalStyle] = React.useState(getModalStyle);

    function getModalStyle() {
        const top = 50;
        const left = 50;

        return {
            top: `${top}%`,
            left: `${left}%`,
            transform: `translate(-${top}%, -${left}%)`,
        };
    }

    const [apiCardType, setApiCardType] = useState('');
    const onApiCardClick = type => {
        setApiCardType(type);
        localStorage.setItem('userType', type);
    };
    useEffect(() => {
        setApiCardType(localStorage.getItem('userType'));
    }, [localStorage.getItem('userType')]);

    return (
        <>
            {localStorage.getItem('isSSO').toString() === 'false' ||
            (localStorage.getItem('isSSO').toString() === 'true' &&
                apiCardType.length > 0) ? (
                <ListDisplayProvider
                    initialListDisplay={initialListDisplay}
                    preferenceName={listDisplayPreferenceName}
                >
                    <List
                        actions={<ApiListActions />}
                        filters={<ApiFilter />}
                        sort={defaultSort}
                        bulkActionButtons={false}
                        component={ApiListComponent}
                        {...props}
                    >
                        <ApiListDisplay />
                    </List>
                </ListDisplayProvider>
            ) : (
                <div className="col-12">
                    <GridList container spacing={4}>
                        <Grid item xs={3}>
                            <Card
                                className={classes.root}
                                onClick={() => onApiCardClick('Internal')}
                            >
                                <CardHeader
                                    className={classes.header}
                                    title={
                                        <Tooltip title={'Internal API'}>
                                            <Typography
                                                variant="h6"
                                                component="span"
                                                display="block"
                                                className={classes.title}
                                                noWrap
                                            >
                                                Internal API
                                            </Typography>
                                        </Tooltip>
                                    }
                                    disableTypography
                                    subheader={
                                        <>
                                            <div className={classes.subheader}>
                                                <Typography
                                                    variant="caption"
                                                    color="textPrimary"
                                                    className={
                                                        classes.ssgServiceType
                                                    }
                                                >
                                                    REST
                                                </Typography>
                                            </div>
                                        </>
                                    }
                                />
                                <CardContent className={classes.content}>
                                    <div className={classes.description}>
                                        List of all Internal NS API's
                                    </div>
                                </CardContent>
                            </Card>
                        </Grid>
                        {/* <Grid item xs={1}></Grid> */}
                        <Grid item xs={3}>
                            <Card
                                className={classes.root}
                                onClick={() => onApiCardClick('External')}
                            >
                                <CardHeader
                                    className={classes.header}
                                    title={
                                        <Tooltip title={'External API'}>
                                            <Typography
                                                variant="h6"
                                                component="span"
                                                display="block"
                                                className={classes.title}
                                                noWrap
                                            >
                                                External API
                                            </Typography>
                                        </Tooltip>
                                    }
                                    disableTypography
                                    subheader={
                                        <>
                                            <div className={classes.subheader}>
                                                <Typography
                                                    variant="caption"
                                                    color="textPrimary"
                                                    className={
                                                        classes.ssgServiceType
                                                    }
                                                >
                                                    REST
                                                </Typography>
                                            </div>
                                        </>
                                    }
                                />
                                <CardContent className={classes.content}>
                                    <div className={classes.description}>
                                        List of all External/Shipment API's
                                    </div>
                                </CardContent>
                            </Card>
                        </Grid>
                    </GridList>
                </div>
            )}
        </>
    );
};

const ApiFilter = props => {
    const translate = useTranslate();
    const classes = useApiFilterStyles();

    return (
        <Filter {...props}>
            <SearchInput
                source="q"
                className={classes.searchInput}
                alwaysOn
                placeholder={translate('resources.apis.list.filters.search')}
            />
            <SelectInput
                source="accessStatus"
                choices={[
                    {
                        id: 'public',
                        name: 'resources.apis.accessStatus.public',
                    },
                    {
                        id: 'private',
                        name: 'resources.apis.accessStatus.private',
                    },
                ]}
            />
            <SelectInput
                source="portalStatus"
                choices={[
                    {
                        id: 'Enabled',
                        name: 'resources.apis.portalStatus.enabled',
                    },
                    {
                        id: 'Disabled',
                        name: 'resources.apis.portalStatus.disabled',
                    },
                    {
                        id: 'Deprecated',
                        name: 'resources.apis.portalStatus.deprecated',
                    },
                    {
                        id: 'New',
                        name: 'resources.apis.portalStatus.unpublished',
                    },
                ]}
            />
            <SelectInput
                // The field is ssgServiceType in the response payload but apiServiceType in filters
                source="apiServiceType"
                choices={[
                    {
                        id: 'SOAP',
                        name: 'SOAP',
                    },
                    {
                        id: 'REST',
                        name: 'REST',
                    },
                ]}
            />
            {localStorage.getItem('isSSO').toString() === 'false' ? (
                <ReferenceArrayInput source="tags" reference="tags">
                    <SelectArrayInput optionText="name" />
                </ReferenceArrayInput>
            ) : null}
        </Filter>
    );
};

const ApiListDisplay = props => {
    const [display] = useListDisplay();

    if (display === LIST_DISPLAY_CARDS) {
        return (
            <>
                {props?.loading ? (
                    <LinearProgress />
                ) : (
                    <CardGrid {...props}>
                        <ApiCard />
                    </CardGrid>
                )}
            </>
        );
    }

    return (
        <Card>
            <Datagrid rowClick="show" {...props}>
                <TruncatedTextField source="name" />
                <MarkdownField source="description" stripTags truncate />
                <TagsField source="tags" sortable={false} />
                <DateField source="createTs" />
                <TruncatedTextField
                    source="version"
                    label="resources.apis.fields.versionShort"
                />
                <TextField source="ssgServiceType" />
                <VisibilityField source="accessStatus" />
                <LastUpdateField source="modifyTs" />
                <AccessField
                    source="portalStatus"
                    translationKey="resources.apis.portalStatus"
                />
            </Datagrid>
        </Card>
    );
};

const ApiListActions = ({
    className,
    currentSort,
    displayedFilters,
    exporter,
    filters,
    filterValues,
    permanentFilter,
    resource,
    showFilter,
    ...props
}) => {
    const classes = useApiListActionsStyles();
    const [display] = useListDisplay();

    return (
        <TopToolbar
            className={classnames(classes.root, className)}
            {...sanitizeListRestProps(props)}
        >
            {filters &&
                cloneElement(filters, {
                    resource,
                    showFilter,
                    displayedFilters,
                    filterValues,
                    context: 'button',
                })}
            {display === LIST_DISPLAY_CARDS ? (
                <ApiListSortButton
                    resource={resource}
                    currentSort={currentSort}
                />
            ) : null}
            <ListDisplayButton className={classes.button} />
        </TopToolbar>
    );
};

export const ApiListSortButton = props => (
    <SortButton {...props}>
        <SortMenuItem
            label="resources.apis.list.sort.name.asc"
            sort={SortByNameASC}
        />
        <SortMenuItem
            label="resources.apis.list.sort.name.desc"
            sort={SortByNameDESC}
        />
        <SortMenuItem
            label="resources.apis.list.sort.createTs.desc"
            sort={SortByCreateTsDESC}
        />
        <SortMenuItem
            label="resources.apis.list.sort.createTs.asc"
            sort={SortByCreateTsASC}
        />
        <SortMenuItem
            label="resources.apis.list.sort.modifyTs.desc"
            sort={SortByModifyTsDESC}
        />
        <SortMenuItem
            label="resources.apis.list.sort.modifyTs.asc"
            sort={SortByModifyTsASC}
        />
    </SortButton>
);

const defaultSort = { field: 'createTs', order: 'DESC' };

const listDisplayPreferenceName = 'listDisplay/apis';

const ApiListComponent = props => <div {...props} />;

const SortByNameASC = { field: 'name', order: 'ASC' };
const SortByNameDESC = { field: 'name', order: 'DESC' };
const SortByCreateTsASC = { field: 'createTs', order: 'ASC' };
const SortByCreateTsDESC = { field: 'createTs', order: 'DESC' };
const SortByModifyTsASC = { field: 'modifyTs', order: 'ASC' };
const SortByModifyTsDESC = { field: 'modifyTs', order: 'DESC' };

const useApiFilterStyles = makeStyles({
    searchInput: {
        minWidth: '300px',
    },
});

const useApiListActionsStyles = makeStyles(
    theme => ({
        root: {
            alignItems: 'center',
        },
        button: {
            marginLeft: theme.spacing(),
        },
    }),
    { name: 'Layer7ApiListActions' }
);

const useStyles = makeStyles(
    theme => ({
        root: {
            zIndex: 2,
            /* backgroundImage: `url('${OverlayFooter}')`, */
            backgroundSize: 'cover',
            marginRight: '10px',
            paddingRight: '10px',
            div: {
                marginLeft: '10px',
            },
            height: '100%',
            display: 'flex',
            flexDirection: 'column',
            cursor: 'pointer',
        },
        sectionTitleLight: {
            color: '#22bcb5',
            marginTop: '74px',
            marginBottom: theme.spacing(3),
            [theme.breakpoints.down('md')]: {
                marginTop: '30px',
            },
        },
        sectionText: {
            display: 'flex',
            alignItems: 'center',
            marginBottom: theme.spacing(3),
            color: '#E7E7E2',
            '& ion-icon': {
                margin: '0 20px 0 5px',
                color: '#22bcb5',
            },
            [theme.breakpoints.down('md')]: {
                fontSize: '14px',
                lineHeight: '17px',
                letterSpacing: '0.07px',
            },
        },
        terms: {
            display: 'flex',
            paddingTop: '60px',
            paddingBottom: '27px',
            alignItems: 'center',
            justifyContent: 'flex-end',
            [theme.breakpoints.down('md')]: {
                margingTop: '56px',
                paddingBottom: '21px',
            },
        },
        MuiCardHeaderContent: {
            paddingLeft: '8px',
        },
        header: {
            borderBottomColor: theme.palette.divider,
            borderBottomWidth: 1,
            borderBottomStyle: 'solid',
        },
        subheader: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-start',
            fontFamily: theme.typography.body2.fontFamily,
            fontSize: theme.typography.caption.fontSize,
            padding: theme.spacing(1, 0),
        },
        description: {
            flex: 1,
            overflow: 'hidden',
            lineClamp: 3,
            boxOrient: 'vertical',
            display: '-webkit-box',
            fontSize: '16px',
            fontFamily: 'adelle-sans-regular, sans-serif',
            fontWeight: 400,
            lineHeight: '20px',
            letterSpacing: '0.08px',
        },
    }),
    {
        name: 'LandingPageFooter',
    }
);
