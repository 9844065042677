import React, { cloneElement } from 'react';
import AppBar from '@material-ui/core/AppBar';
import Divider from '@material-ui/core/Divider';
import Toolbar from '@material-ui/core/Toolbar';
import { makeStyles } from '@material-ui/core/styles';
import { LoadingIndicator, useLocale } from 'react-admin';
import Typography from '@material-ui/core/Typography';
import { Button } from '@material-ui/core';
import { Link as RouterLink } from 'react-router-dom';
import classnames from 'classnames';

import { nscorpTheme } from './theme';
import { BrandLogo } from './ui/BrandLogo';
import { ApiHubUserMenu } from './ApiHubUserMenu';
import { ApiHubLanguageSwitcher } from './ApiHubLanguageSwitcher';
import { SidebarButton } from './SidebarButton';

/**
 * The ApiHub AppBar used in the ApiHub Layout.
 *
 * @param {*} props AppBar properties
 */
export const ApiHubAppBar = ({
    children,
    classes: classesOverride,
    className,
    languagesMenu,
    logo,
    logout,
    open,
    sidebarButton,
    title,
    userMenu,
    ...rest
}) => {
    const classes = useStyles({ classes: classesOverride });
    useLocale(); // Make sure the locale change would rerender the component

    return (
        <AppBar className={className} color="secondary" elevation={0} {...rest}>
            <Toolbar
                disableGutters
                variant="regular"
                className={classes.toolbar}
            >
                <BrandLogo />
                <Typography
                    variant="inherit"
                    className={classes.nsHeader}
                    color="textSecondary"
                >
                    NS API Resource Platform
                </Typography>
                <div className={classes.header}>{children}</div>
                <LoadingIndicator />
                {cloneElement(languagesMenu)}
                <Divider className={classes.divider} orientation="vertical" />
                {cloneElement(userMenu, { logout })}
            </Toolbar>
            <Toolbar className={classnames(classes.menuToolbar)}>
                <Button
                    className={classnames(classes.menuToolbarOptions)}
                    component={RouterLink}
                    disableRipple
                    to="/apis"
                    onClick={() => localStorage.setItem('userType', '')}
                >
                    APIs
                </Button>
                <Button
                    className={classnames(classes.menuToolbarOptions)}
                    component={RouterLink}
                    disableRipple
                    to="/applications"
                    onClick={() => localStorage.setItem('userType', '')}
                >
                    Applications
                </Button>
            </Toolbar>
        </AppBar>
    );
};

ApiHubAppBar.defaultProps = {
    userMenu: <ApiHubUserMenu />,
    languagesMenu: <ApiHubLanguageSwitcher />,
    sidebarButton: <SidebarButton />,
};

const useStyles = makeStyles(
    theme => ({
        toolbar: {
            paddingLeft: 30,
            paddingRight: 24,
            height: '70px',
        },
        menuToolbar: {
            height: '66px',
            paddingLeft: '16px',
        },
        menuToolbarOptions: {
            color: '#fff',
            '&:hover': {
                color: nscorpTheme.palette.primary.main,
            },
        },
        header: {
            flex: 1,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-start',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
        },
        nsHeader: {
            paddingLeft: '30px',
            fontSize: '24px',
        },
        divider: {
            alignSelf: 'stretch',
            backgroundColor: theme.palette.secondary.contrastText,
            height: 'auto',
            marginBottom: theme.spacing(2),
            marginLeft: theme.spacing(4),
            marginRight: theme.spacing(4),
            marginTop: theme.spacing(2),
        },
    }),
    {
        name: 'Layer7AppBar',
    }
);
