var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g = Object.create((typeof Iterator === "function" ? Iterator : Object).prototype);
    return g.next = verb(0), g["throw"] = verb(1), g["return"] = verb(2), typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { promisify } from '../promisify';
import { Response } from 'miragejs';
var UuidRegexp = /Eulas\('(.*)'\)/;
var SearchFields = ['name', 'description'];
export function listApiGroups(database) {
    var _this = this;
    return function (schema, request) { return __awaiter(_this, void 0, void 0, function () {
        var _a, page, size, order, sort, filter, finalPage, finalSize, _b, finalSort, finalOrder, finalFilters, otherFilters, totalElements, results;
        var _c;
        return __generator(this, function (_d) {
            switch (_d.label) {
                case 0:
                    _a = request.queryParams, page = _a.page, size = _a.size, order = _a.order, sort = _a.sort, filter = __rest(_a, ["page", "size", "order", "sort"]);
                    finalPage = parseInt(page, 10);
                    finalSize = parseInt(size, 10);
                    _b = sort ? sort.split(',') : [], finalSort = _b[0], finalOrder = _b[1];
                    finalFilters = filter;
                    if (Object.keys(filter).some(function (key) { return SearchFields.includes(key); })) {
                        otherFilters = Object.keys(filter).filter(function (key) { return !SearchFields.includes(key); });
                        finalFilters = otherFilters.reduce(function (acc, key) {
                            var _a;
                            return (__assign(__assign({}, acc), (_a = {}, _a[key] = filter[key], _a)));
                        }, {
                            $or: SearchFields.map(function (field) {
                                var _a;
                                return (_a = {},
                                    _a[field] = {
                                        $regex: ".*".concat(filter[field], ".*"),
                                        $options: 'ig',
                                    },
                                    _a);
                            }),
                        });
                    }
                    return [4 /*yield*/, promisify(database.apiGroups.find(finalFilters).fetch)];
                case 1:
                    totalElements = (_d.sent()).length;
                    return [4 /*yield*/, promisify(database.apiGroups.find(finalFilters, {
                            limit: finalSize,
                            skip: finalSize * finalPage,
                            sort: (_c = {},
                                _c[finalSort] = (finalOrder === null || finalOrder === void 0 ? void 0 : finalOrder.toLowerCase()) === 'asc' ? 1 : -1,
                                _c),
                        }).fetch)];
                case 2:
                    results = _d.sent();
                    return [2 /*return*/, {
                            results: results,
                            totalElements: totalElements,
                        }];
            }
        });
    }); };
}
export function getApiGroup(database) {
    var _this = this;
    return function (schema, request) { return __awaiter(_this, void 0, void 0, function () {
        var apiGroup;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, promisify(database.apiGroups.findOne.bind(database.apiGroups), { uuid: request.params.id }, {})];
                case 1:
                    apiGroup = _a.sent();
                    return [2 /*return*/, apiGroup];
            }
        });
    }); };
}
export function getApiGroupApis(database) {
    var _this = this;
    return function (schema, request) { return __awaiter(_this, void 0, void 0, function () {
        var apiGroup;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, promisify(database.apiGroups.findOne.bind(database.apiGroups), { uuid: request.params.id }, {})];
                case 1:
                    apiGroup = _a.sent();
                    return [2 /*return*/, apiGroup.apis.map(function (_a) {
                            var uuid = _a.uuid;
                            return ({ uuid: uuid });
                        })];
            }
        });
    }); };
}
export function getApiGroupEula(database) {
    var _this = this;
    return function (schema, request) { return __awaiter(_this, void 0, void 0, function () {
        var matches, Uuid, eula;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    matches = UuidRegexp.exec(request.params.path);
                    if (!(matches && matches.length > 1)) return [3 /*break*/, 2];
                    Uuid = matches[1];
                    return [4 /*yield*/, promisify(database.apiEulas.findOne.bind(database.apiEulas), { Uuid: Uuid })];
                case 1:
                    eula = _a.sent();
                    if (eula) {
                        return [2 /*return*/, eula];
                    }
                    _a.label = 2;
                case 2: return [2 /*return*/, new Response(404)];
            }
        });
    }); };
}
