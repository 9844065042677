var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g = Object.create((typeof Iterator === "function" ? Iterator : Object).prototype);
    return g.next = verb(0), g["throw"] = verb(1), g["return"] = verb(2), typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import differenceInDays from 'date-fns/differenceInDays';
import eachDayOfInterval from 'date-fns/eachDayOfInterval';
import eachMonthOfInterval from 'date-fns/eachMonthOfInterval';
import parseISO from 'date-fns/parseISO';
export function getMetricsHits(database) {
    var _this = this;
    return function (schema, request) { return __awaiter(_this, void 0, void 0, function () {
        var _a, startDate, endDate, parsedStartDate, parsedEndDate, diff, hitsTs, hits;
        return __generator(this, function (_b) {
            _a = request.queryParams, startDate = _a.startDate, endDate = _a.endDate;
            parsedStartDate = parseISO(startDate);
            parsedEndDate = parseISO(endDate);
            diff = Math.abs(differenceInDays(parsedStartDate, parsedEndDate));
            if (diff > 31) {
                hitsTs = eachMonthOfInterval({
                    start: parsedStartDate,
                    end: parsedEndDate,
                }).map(function (date) { return ({
                    date: date,
                    hits: Math.floor(Math.random() * 5000 + 2000),
                }); });
            }
            else {
                hitsTs = eachDayOfInterval({
                    start: parsedStartDate,
                    end: parsedEndDate,
                }).map(function (date) { return ({
                    date: date,
                    hits: Math.floor(Math.random() * 200 + 100),
                }); });
            }
            hits = hitsTs.reduce(function (acc, currentHit) {
                return currentHit > acc ? currentHit : acc;
            });
            return [2 /*return*/, {
                    respCode: 200,
                    respMsg: 'Request Processed Successfully',
                    requestId: 'Gen-ad1d18f8-8ada-4e06-97fb-eedc715f84bd',
                    query: {
                        timerange: 'custom',
                        startTimeGMT: startDate,
                        endTimeGMT: endDate,
                        sortby: 'hits(default)',
                        sortorder: 'asc(default)',
                    },
                    data: {
                        hits: { hits: hits },
                        hitsTs: hitsTs,
                    },
                }];
        });
    }); };
}
export function getMetricsLatency(database) {
    var _this = this;
    return function (schema, request) { return __awaiter(_this, void 0, void 0, function () {
        var _a, startDate, endDate, parsedStartDate, parsedEndDate, diff, latenciesTs, getLatency, latencies;
        return __generator(this, function (_b) {
            _a = request.queryParams, startDate = _a.startDate, endDate = _a.endDate;
            parsedStartDate = parseISO(startDate);
            parsedEndDate = parseISO(endDate);
            diff = Math.abs(differenceInDays(parsedStartDate, parsedEndDate));
            getLatency = function (date) {
                var min = Math.floor(Math.random() * 100);
                var max = Math.floor(Math.random() * 100 + 300);
                return {
                    date: date,
                    avg: (min + max) / 2,
                    min: min,
                    max: max,
                };
            };
            if (diff > 31) {
                latenciesTs = eachMonthOfInterval({
                    start: parsedStartDate,
                    end: parsedEndDate,
                }).map(getLatency);
            }
            else {
                latenciesTs = eachDayOfInterval({
                    start: parsedStartDate,
                    end: parsedEndDate,
                }).map(getLatency);
            }
            latencies = {
                max: latenciesTs.reduce(function (acc, latency) {
                    return latency.max > acc ? latency.max : acc;
                }),
                min: latenciesTs.reduce(function (acc, latency) {
                    return latency.min < acc ? latency.min : acc;
                }),
                avg: latenciesTs.reduce(function (acc, latency) { return acc + latency.avg; }) /
                    latenciesTs.length,
            };
            return [2 /*return*/, {
                    respCode: 200,
                    respMsg: 'Request Processed Successfully',
                    requestId: 'Gen-89ecd7b4-6a90-48d4-8e2b-630b3f86d12d',
                    query: {
                        stats: 'avg,max,min',
                        timerange: 'custom',
                        startTimeGMT: '2020-01-05T00:00:00(default)',
                        endTimeGMT: '2020-01-11T23:59:59(default)',
                        sortby: 'avg(default)',
                        sortorder: 'asc(default)',
                    },
                    data: {
                        latencies: latencies,
                        latenciesTs: latenciesTs,
                    },
                }];
        });
    }); };
}
