import React, { useEffect, useState } from 'react';
import { makeStyles, Typography } from '@material-ui/core';
import { useTranslate, useCheckAuth, useRedirect } from 'react-admin';
import Button from '@material-ui/core/Button';

import { useClearNotifications } from '../../ui';
import { LoginForm } from './LoginForm';
import { AuthenticationLayout } from '../AuthenticationLayout';
import { CustomDialog } from '../../common';

/**
 * The login component which includes the login form and the signup link.
 * Redirects to the main application page if the user is already logged in.
 */
export const Login = props => {
    const classes = useStyles(props);
    const translate = useTranslate();

    const { SSO_REDIRECT_URL } = global.APIHUB_CONFIG;

    const handleButtonClick = () => {
        localStorage.setItem('userType', '');
        localStorage.setItem('isSSO', true);
        window.location.href = SSO_REDIRECT_URL;
    };

    const clearNotifications = useClearNotifications();
    const [open, setOpen] = useState(false);

    useEffect(() => {
        localStorage.setItem('isSSO', false);
        localStorage.setItem('userType', '');
        clearNotifications();
    }, [clearNotifications]);

    const checkAuth = useCheckAuth();
    const redirect = useRedirect();
    useEffect(() => {
        setOpen(props.openModal?.open);
        checkAuth({}, false)
            .then(() => {
                // Redirects the main page
                // if the user is already authenticated
                redirect('/');
            })
            .catch(() => {
                // Not authenticated, stay on the login page
            });
    }, [checkAuth, redirect]);

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <>
            <Typography
                variant="h4"
                color="textPrimary"
                className={classes.title}
            >
                {translate('apihub.login.title')}
            </Typography>
            <LoginForm {...props} />
            <CustomDialog
                title={'common.labels.confirmation'}
                content={
                    'resources.registrations.notifications.successful_registration'
                }
                open={open}
                onClose={handleClose}
            />
            <Typography
                className={classes.orDiv}
                variant="body1"
                align="center"
            >
                {/* OR */}
            </Typography>
            {/* <Button
                variant="contained"
                color="primary"
                onClick={handleButtonClick}
            >
                NSCORP SSO
            </Button> */}
        </>
    );
};
export const LoginPage = props => (
    <AuthenticationLayout {...props}>
        <Login />
    </AuthenticationLayout>
);

const useStyles = makeStyles(
    theme => ({
        title: {
            marginBottom: theme.spacing(2),
        },
        orDiv: {
            paddingTop: '16px',
            margin: theme.spacing(2),
        },
    }),
    {
        name: 'Layer7Login',
    }
);
