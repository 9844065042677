import React, { cloneElement, useState, useEffect } from 'react';
import {
    Filter,
    sanitizeListRestProps,
    SelectInput,
    TopToolbar,
    CreateButton,
} from 'react-admin';
import { useTranslate } from 'ra-core';
import classnames from 'classnames';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import LinearProgress from '@material-ui/core/LinearProgress';
import Card from '@material-ui/core/Card';

import { readApiHubPreference } from '../preferences';
import {
    AccessField,
    CardGrid,
    Datagrid,
    List,
    ListDisplayButton,
    ListDisplayProvider,
    LIST_DISPLAY_CARDS,
    SortButton,
    SortMenuItem,
    TruncatedTextField,
    useListDisplay,
} from '../ui';
import { useUserContext } from '../userContexts';
import { isAdminUser } from '../userContexts';
import { ApplicationCard } from './ApplicationCard';
import Inbox from '@material-ui/icons/Inbox';

import Tooltip from '@material-ui/core/Tooltip';
import { CardContent, CardHeader, Grid, GridList } from '@material-ui/core';

const defaultSort = { field: 'name', order: 'ASC' };

const listDisplayPreferenceName = 'listDisplay/applications';

const useEmptyStyles = makeStyles(
    theme => ({
        message: {
            textAlign: 'center',
            opacity: theme.palette.type === 'light' ? 0.5 : 0.8,
            margin: '0 1em',
            color:
                theme.palette.type === 'light'
                    ? 'inherit'
                    : theme.palette.text.primary,
        },
        icon: {
            width: '9em',
            height: '9em',
        },
        toolbar: {
            textAlign: 'center',
            marginTop: '2em',
        },
    }),
    { name: 'Empty' }
);
const Empty = ({ canCRUD, basePath, ...props }) => {
    const classes = useEmptyStyles(props);
    const translate = useTranslate();
    return (
        <>
            <div className={classes.message}>
                <Inbox className={classes.icon} />
                <Typography variant="h6" paragraph>
                    {translate('resources.applications.fields.noApplications')}
                </Typography>
            </div>
            {canCRUD && (
                <div className={classes.toolbar}>
                    <CreateButton
                        basePath={basePath}
                        variant="contained"
                        color="primary"
                        label="resources.applications.actions.addApplication"
                    />
                </div>
            )}
        </>
    );
};

export const ApplicationList = props => {
    const initialListDisplay = readApiHubPreference(
        listDisplayPreferenceName,
        LIST_DISPLAY_CARDS
    );
    const [userContext] = useUserContext();
    const [canCRUD, setCanCRUD] = React.useState(false);
    const classes = useStyles();
    const [modalStyle] = React.useState(getModalStyle);

    function getModalStyle() {
        const top = 50;
        const left = 50;

        return {
            top: `${top}%`,
            left: `${left}%`,
            transform: `translate(-${top}%, -${left}%)`,
        };
    }

    const [apiCardType, setApplicationCardType] = useState('');
    const onApiCardClick = type => {
        setApplicationCardType(type);
        localStorage.setItem('userType', type);
    };
    useEffect(() => {
        setApplicationCardType(localStorage.getItem('userType'));
    }, [localStorage.getItem('userType')]);

    React.useEffect(() => {
        if (userContext && isAdminUser(userContext)) {
            setCanCRUD(true);
        }
    }, [userContext]);

    return (
        <>
            {localStorage.getItem('isSSO').toString() === 'false' ||
            (localStorage.getItem('isSSO').toString() === 'true' &&
                apiCardType.length > 0) ? (
                <ListDisplayProvider
                    initialListDisplay={initialListDisplay}
                    preferenceName={listDisplayPreferenceName}
                >
                    <List
                        empty={<Empty canCRUD={canCRUD} />}
                        actions={<ApplicationListActions canCRUD={canCRUD} />}
                        filter={{
                            $select: 'Name,Uuid,ApiKey,Status,Description',
                        }}
                        filters={<ApplicationFilter />}
                        sort={defaultSort}
                        bulkActionButtons={false}
                        component={ApplicationListComponent}
                        {...props}
                    >
                        <ApplicationListDisplay canCRUD={canCRUD} />
                    </List>
                </ListDisplayProvider>
            ) : (
                <div className="col-12">
                    <GridList container spacing={4}>
                        <Grid item xs={3}>
                            <Card
                                className={classes.root}
                                onClick={() => onApiCardClick('Internal')}
                            >
                                <CardHeader
                                    className={classes.header}
                                    title={
                                        <Tooltip
                                            title={'Internal Applications'}
                                        >
                                            <Typography
                                                variant="h6"
                                                component="span"
                                                display="block"
                                                className={classes.title}
                                                noWrap
                                            >
                                                Internal Applications
                                            </Typography>
                                        </Tooltip>
                                    }
                                    disableTypography
                                />
                                <CardContent className={classes.content}>
                                    <div className={classes.description}>
                                        List of all Internal NS Application's
                                    </div>
                                </CardContent>
                            </Card>
                        </Grid>
                        {/* <Grid item xs={1}></Grid> */}
                        <Grid item xs={3}>
                            <Card
                                className={classes.root}
                                onClick={() => onApiCardClick('External')}
                            >
                                <CardHeader
                                    className={classes.header}
                                    title={
                                        <Tooltip
                                            title={'External Applications'}
                                        >
                                            <Typography
                                                variant="h6"
                                                component="span"
                                                display="block"
                                                className={classes.title}
                                                noWrap
                                            >
                                                External Applications
                                            </Typography>
                                        </Tooltip>
                                    }
                                    disableTypography
                                />
                                <CardContent className={classes.content}>
                                    <div className={classes.description}>
                                        List of all External NS Application's
                                    </div>
                                </CardContent>
                            </Card>
                        </Grid>
                    </GridList>
                </div>
            )}
        </>
    );
};

const ApplicationListComponent = props => <div {...props} />;

const ApplicationFilter = props => {
    return (
        <Filter {...props}>
            <SelectInput
                source="status"
                choices={[
                    {
                        id: 'ENABLED',
                        name: 'resources.applications.status.enabled',
                    },
                    {
                        id: 'DISABLED',
                        name: 'resources.applications.status.disabled',
                    },
                    {
                        id: 'APPLICATION_PENDING_APPROVAL',
                        name:
                            'resources.applications.status.application_pending_approval',
                    },
                ]}
            />
        </Filter>
    );
};

const ApplicationListDisplay = props => {
    const { canCRUD } = props;
    const [display] = useListDisplay();
    const classes = useApplicationListStyles();

    if (display === LIST_DISPLAY_CARDS) {
        return (
            <>
                {props?.loading ? (
                    <LinearProgress />
                ) : (
                    <CardGrid {...props}>
                        <ApplicationCard canCRUD={canCRUD} />
                    </CardGrid>
                )}
            </>
        );
    }

    return (
        <Card className={classes.root}>
            <Datagrid className={classes.datagrid} rowClick="show" {...props}>
                <TruncatedTextField
                    source="name"
                    cellClassName={classes.name}
                />
                <TruncatedTextField
                    source="description"
                    cellClassName={classes.description}
                />
                <AccessField
                    source="status"
                    translationKey="resources.applications.status"
                    cellClassName={classes.status}
                />
            </Datagrid>
        </Card>
    );
};

const useApplicationListStyles = makeStyles(
    theme => ({
        root: {},
        datagrid: {},
        name: {},
        status: {},
        description: {
            '& span': {
                maxWidth: '30vw',
                [theme.breakpoints.up('lg')]: {
                    maxWidth: '25vw',
                },
            },
        },
    }),
    {
        name: 'Layer7ApplicationList',
    }
);

const useApplicationListActionsStyles = makeStyles(theme => ({
    root: {
        alignItems: 'center',
    },
    button: {
        marginLeft: theme.spacing(),
    },
    createButton: {
        paddingRight: theme.spacing(2),
        marginRight: theme.spacing(2),
    },
}));

const useStyles = makeStyles(
    theme => ({
        root: {
            zIndex: 2,
            /* backgroundImage: `url('${OverlayFooter}')`, */
            backgroundSize: 'cover',
            marginRight: '10px',
            paddingRight: '10px',
            div: {
                marginLeft: '10px',
            },
            height: '100%',
            display: 'flex',
            flexDirection: 'column',
            cursor: 'pointer',
        },
        sectionTitleLight: {
            color: '#22bcb5',
            marginTop: '74px',
            marginBottom: theme.spacing(3),
            [theme.breakpoints.down('md')]: {
                marginTop: '30px',
            },
        },
        sectionText: {
            display: 'flex',
            alignItems: 'center',
            marginBottom: theme.spacing(3),
            color: '#E7E7E2',
            '& ion-icon': {
                margin: '0 20px 0 5px',
                color: '#22bcb5',
            },
            [theme.breakpoints.down('md')]: {
                fontSize: '14px',
                lineHeight: '17px',
                letterSpacing: '0.07px',
            },
        },
        terms: {
            display: 'flex',
            paddingTop: '60px',
            paddingBottom: '27px',
            alignItems: 'center',
            justifyContent: 'flex-end',
            [theme.breakpoints.down('md')]: {
                margingTop: '56px',
                paddingBottom: '21px',
            },
        },
        MuiCardHeaderContent: {
            paddingLeft: '8px',
        },
        header: {
            borderBottomColor: theme.palette.divider,
            borderBottomWidth: 1,
            borderBottomStyle: 'solid',
        },
        subheader: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-start',
            fontFamily: theme.typography.body2.fontFamily,
            fontSize: theme.typography.caption.fontSize,
            padding: theme.spacing(1, 0),
        },
        description: {
            flex: 1,
            overflow: 'hidden',
            lineClamp: 3,
            boxOrient: 'vertical',
            display: '-webkit-box',
            fontSize: '16px',
            fontFamily: 'adelle-sans-regular, sans-serif',
            fontWeight: 400,
            lineHeight: '20px',
            letterSpacing: '0.08px',
        },
    }),
    {
        name: 'LandingPageFooter',
    }
);

const ApplicationListActions = ({
    className,
    currentSort,
    displayedFilters,
    exporter,
    filters,
    filterValues,
    permanentFilter,
    resource,
    showFilter,
    hasCreate,
    basePath,
    canCRUD,
    ...props
}) => {
    const classes = useApplicationListActionsStyles();
    const [display] = useListDisplay();

    return (
        <TopToolbar
            className={classnames(classes.root, className)}
            {...sanitizeListRestProps(props)}
        >
            {canCRUD && (
                <CreateButton
                    basePath={basePath}
                    variant="contained"
                    color="primary"
                    className={classes.createButton}
                    label="resources.applications.actions.addApplication"
                />
            )}
            {filters &&
                cloneElement(filters, {
                    resource,
                    showFilter,
                    displayedFilters,
                    filterValues,
                    context: 'button',
                })}
            {display === LIST_DISPLAY_CARDS ? (
                <ApplicationListSortButton
                    resource={resource}
                    currentSort={currentSort}
                />
            ) : null}
            <ListDisplayButton className={classes.button} />
        </TopToolbar>
    );
};

export const ApplicationListSortButton = props => (
    <SortButton {...props}>
        <SortMenuItem
            label="resources.applications.list.sort.name.asc"
            sort={SortByNameASC}
        />
        <SortMenuItem
            label="resources.applications.list.sort.name.desc"
            sort={SortByNameDESC}
        />
    </SortButton>
);

const SortByNameASC = { field: 'name', order: 'ASC' };
const SortByNameDESC = { field: 'name', order: 'DESC' };
